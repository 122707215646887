
.clear-button {
  position: absolute;
  right: 5px;
  top: 35%;
  transform: translateY(-50%);
}

/* Media query for mobile responsiveness */
@media (max-width: 767px) {
  .clear-button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.input-group-text {
  position: relative; /* Ensure the container is positioned */
}


.mdi-dots-horizontal::after {
  content: "";
  position: absolute;
  left: 13px;
  bottom: 13px;
  width: 40%;
  height: 1px;
  background-color: black; /* Or the color you prefer */
}


.graybd{
    
    background:#dbdbdb !important;
    background-color: gray !important;
    
}

body > iframe {
  display: none;
}

.image2 {
  margin-top: 15%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 5%;

}

.imputsys {
  background: white;
    padding: 0.47rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
}




.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(104 96 96 / 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    transition: background-color 0.7s ease-in-out;
  }

  .graytext {
    display: block;
    
    
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ebebeb;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    
      border: 1px solid rgb(219, 219, 219);
      border-radius: 10px;
      height: 2.5rem;
      padding-left: 1rem;
    
    }
  
  .modal-content2 {
    background: rgb(248, 247, 255);
    padding: 20px;
    border-radius: 4px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .confirm-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 16px;
    margin-right: 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }

  